import { FC } from "react";
import dvgLogo from "../../assets/1.webp";

const StepsList: FC = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto flex flex-wrap">
        <h2 className="mb-6 text-3xl w-full font-extrabold text-center leading-tight tracking-tight text-gray-900 lg:text-center dark:text-white md:text-4xl">
          Направления
        </h2>
        <h3 className="mb-10 text-lg font-normal text-gray-500 dark:text-gray-400 lg:text-center lg:text-xl lg:px-64 lg:mb-16">
          Мы специализируемся на широком спектре
          направлений. Наша команда опытных специалистов
          проектирует и создает инновационные решения,
          которые удовлетворяют потребностям самых
          требовательных клиентов. Мы гарантируем
          профессиональное ведение проекта от начала и до
          конца
        </h3>

        <div className="flex flex-wrap w-full">
          <div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
            <div className="flex relative pb-12">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div
                data-aos-duration="1500"
                data-aos="fade-right"
                className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h4 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                  Машинное обучение
                </h4>
                <h5 className="leading-relaxed">
                  Услуги машинного обучения и data science
                </h5>
              </div>
            </div>
            <div className="flex relative pb-12">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div
                data-aos-duration="1500"
                data-aos="fade-right"
                className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 12h-4l-3 9L9 3l-3 9h4"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h4 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                  Full-Stack приложения
                </h4>
                <h5 className="leading-relaxed">
                  Разработаем FullStack приложения любой
                  сложности под любые необходимые задачи
                </h5>
              </div>
            </div>
            <div className="flex relative pb-12">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div
                data-aos-duration="1500"
                data-aos="fade-right"
                className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <circle cx="12" cy="5" r="3"></circle>
                  <path d="M12 22V8M5 12h4a10 10 0 0020 0h-3"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h4 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                  iOS / Android приложения
                </h4>
                <h5 className="leading-relaxed">
                  Разработаем качественное
                  кроссплатформенное приложения под ваши
                  нужды
                </h5>
              </div>
            </div>
            <div className="flex relative pb-12">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div
                data-aos-duration="1500"
                data-aos="fade-right"
                className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h4 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                  Консультации
                </h4>
                <h5 className="leading-relaxed">
                  Мы с радостью поможем вам принять
                  правильное решение относительно задачи
                  связанной с созданием сайтов, их
                  обслуживанием или продвижением сайта в
                  интернете.
                </h5>
              </div>
            </div>
            <div className="flex relative">
              <div
                data-aos-duration="1500"
                data-aos="fade-right"
                className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                  <path d="M22 4L12 14.01l-3-3"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h4 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                  Bitrix-24
                </h4>
                <h5 className="leading-relaxed">
                  Интегрируем Bitrix-24 и всего его сервисы
                  под ключ. Битрикс24 помогает руководству
                  управлять бизнесом и выстроить грамотную
                  рабочую среду для своих сотрудников
                </h5>
              </div>
              z
            </div>
          </div>
          <img
            loading="lazy"
            data-aos-duration="1500"
            data-aos="zoom-in"
            className="lg:w-3/5 md:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12"
            src={dvgLogo}
            alt="step"
          />
        </div>
      </div>
    </section>
  );
};

export default StepsList;
