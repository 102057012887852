import React from 'react'
import { Button, Result } from 'antd'

const My404Component: React.FC = () => (
  <Result
    status="404"
    title="404"
    subTitle="Извините, страница не найдена"
    extra={
      <Button className="bg-blue-600" type="primary">
        Домой
      </Button>
    }
  />
)

export default My404Component
