import { useEffect } from 'react'
import './App.css'
import My404Component from './app/404'
import MainPage from './app/MainPage'
import AOS from 'aos'
import 'aos/dist/aos.css' // You can also use <link> for styles
import Header from './components/ui/Header'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Footer from './components/ui/Footer'
import ProjectPage from './app/ProjectPage'
import ScrollToTop from './components/layout/ScrollToTop'

function App() {
  useEffect(() => {
    AOS.init()
  }, [])
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop>
          <Header />
          <div className="min-h-screen">
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/projects/:projectId" element={<ProjectPage />} />
              <Route path="*" element={<My404Component />} />
            </Routes>
          </div>
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    </div>
  )
}

export default App
