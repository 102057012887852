import Logotype from "./Logotype";

const Footer: React.FC = () => {
  return (
    <footer className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left md:mt-0 mt-10">
          <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
            <Logotype />
            <span className="ml-3 text-xl">
              DvG Project
            </span>
          </a>
          <p className="mt-2 text-sm text-gray-500">
            Мы располагаем компетенциями и опытом
            для разработки и внедрения самых
            смелых идей
          </p>
        </div>
        <div className="flex-grow flex flex-wrap md:pr-20 -mb-10 md:text-left text-center order-first">
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              Продукт или услуга
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a className="text-gray-600 hover:text-gray-800">
                  Бизнес план
                </a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800">
                  Создание MVP
                </a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800">
                  Заказная разработка
                </a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800">
                  Управление проектом
                </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              Производство
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a className="text-gray-600 hover:text-gray-800">
                  Внедрение Bitrix-24
                </a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800">
                  Заказная разработка ПО и ПАК
                </a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800">
                  Создание фирменного стиля
                </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              Продажи
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a className="text-gray-600 hover:text-gray-800">
                  Реклама Vk, Yandex, MyTarget
                </a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800">
                  Маркетплейсы
                </a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800">
                  Колтрекеры
                </a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800">
                  Аналитика
                </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              Платежи
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a className="text-gray-600 hover:text-gray-800">
                  Платёжные системы
                </a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800">
                  Контроль дебиторки
                </a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800">
                  Управленческий счёт
                </a>
              </li>
            </nav>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p className="text-gray-500 text-sm text-center sm:text-left">
            © 2023 DvG Project —
            <a
              href="https://github.com/alexandr-seredkin"
              rel="noopener noreferrer"
              className="text-gray-600 ml-1"
              target="_blank"
            >
              @salemdev
            </a>
          </p>
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
            
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
