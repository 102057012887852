import { IProject } from '../store/Portfolio/PortfolioProject.types';

import promoImg from '../assets/cases/promo.webp';
import interioImg from '../assets/cases/interio.webp';
import sineoImg from '../assets/cases/sineo.webp';
import dspImg from '../assets/cases/dsp.webp';
import fastomatImg from '../assets/cases/fastomat.webp';
import webmobilImg from '../assets/cases/webmobil.webp';
import takeyoursImg from '../assets/cases/takeyours.webp';
import nomitechImg from '../assets/cases/nomitech.webp';
import confbitrixImg from '../assets/cases/confbitrix.webp';

export const ProjectsData: IProject[] = [
  {
    id: 1,
    title: 'Take Yours',
    description: 'Лендинг',
    imageUrl: promoImg,
    link: 'https://www.take-yours.online/promo/'
  },
  {
    id: 2,
    title: 'Интерио',
    description: 'Сайт под ключ',
    imageUrl: interioImg,
    link: 'https://interio31.ru/'
  },
  {
    id: 3,
    title: 'СИНЭО',
    description: 'Сайт и CRM',
    imageUrl: sineoImg,
    link: 'https://inexpert.ru/'
  },
  {
    id: 4,
    title: 'Дома из сип-панелей',
    description: 'Сайт под ключ',
    imageUrl: dspImg,
    link: 'https://дом-сип-панель.рф/'
  },
  {
    id: 5,
    title: 'Fastomat',
    description: 'HoReCa и Retail система',
    imageUrl: fastomatImg,
    link: 'https://www.fastomat.ru/'
  },
  {
    id: 6,
    title: 'WebMobil',
    description: 'Маргетинговое агенство',
    imageUrl: webmobilImg,
    link: 'https://webmobil-agency.ru/'
  },
  {
    id: 7,
    title: 'Take Yours',
    description: 'Система сборки заказов',
    imageUrl: takeyoursImg,
    link: 'https://www.take-yours.online/'
  },
  {
    id: 8,
    title: 'Nomitech',
    description: 'Сайт под ключ',
    imageUrl: nomitechImg,
    link: 'https://nomitech.ru/'
  },
  {
    id: 9,
    title: 'Bitrix-24',
    description: 'Конференция',
    imageUrl: confbitrixImg,
    link: 'https://belconference.bitrix24site.ru/'
  }
];
