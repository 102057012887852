import React from "react";
import { IProject } from "../../store/Portfolio/PortfolioProject.types";

interface PortfolioProjectProps {
  project: IProject;
  aos: string;
}

const PortfolioProject: React.FC<PortfolioProjectProps> = ({ project, aos }) => {
  return (
    <div
      data-aos={aos}
      data-aos-duration="1500"
      className="w-full px-4 md:w-1/2 xl:w-1/3"
    >
      <div className="relative mb-12">
        <div className="overflow-hidden rounded-[10px]">
          <img
            height="228px"
            src={project.imageUrl}
            alt="portfolio"
            className="w-full border-2"
          />
        </div>
        <div className="relative z-10 mx-7 -mt-20 rounded-lg bg-white dark:bg-dark-2 py-[34px] px-3 text-center shadow-portfolio dark:shadow-box-dark">
          <span className="text-primary mb-2 block text-sm font-medium">
            {project.title}
          </span>
          <h3 className="text-dark dark:text-white mb-5 text-xl font-bold">
            {project.description}
          </h3>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={project.link}
            className="text-body-color dark:text-dark-6 hover:border-primary hover:bg-primary inline-block rounded-md border border-stroke dark:border-dark-3 py-[10px] px-7 text-sm font-medium transition hover:text-white hover:bg-purple-800"
          >
            Перейти на сайт
          </a>
        </div>
      </div>
    </div>
  );
};

export default PortfolioProject;
