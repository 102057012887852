import { FC } from "react";
import { observer } from "mobx-react-lite";
import { handleSubmit } from "../../api/api";
import useForm from '../../hooks/useForm';
import ApplicationAcceptedModal from "../ui/Modals/ApplicationAcceptedModal";

const MapContact: FC = () => {
  const { formState, handleChange, handleFormSubmit, errors, loading, formSubmitted } = useForm({
    initialState: { email: '', message: '', phone: '' },
    handleSubmit,
  });

  return (
    <section className="text-gray-700 body-font relative border-none">
      <div className="absolute inset-0 bg-gray-300">
        <iframe
          loading="lazy"
          title="map"
          src="https://yandex.ru/map-widget/v1/?ll=36.583534%2C50.599134&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NTk4MzE2NRJN0KDQvtGB0YHQuNGPLCDQkdC10LvQs9C-0YDQvtC0LCDQn9GA0LXQvtCx0YDQsNC20LXQvdGB0LrQsNGPINGD0LvQuNGG0LAsIDY50JEiCg2bVRJCFZFlSkI%2C&z=16"
          width="100%"
          height="100%"
        ></iframe>
      </div>
      <div className="container px-5 py-24 mx-auto flex">
        <div className="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10">
          <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
            Обратная связь
          </h2>
          <p className="leading-relaxed mb-5 text-sm text-gray-600">
            Ваши мысли - наш бизнес: оставьте след в мире
            технологий через нашу форму обратной связи!
          </p>
          <form onSubmit={handleFormSubmit} className="flex flex-col">
            <input
              name="email"
              className="bg-white rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 py-2 mb-4"
              placeholder="Email"
              type="email"
              value={formState.email}
              onChange={handleChange}
            />
            {errors.email && (
              <p className="text-red-600 text-xs break-words max-w-[320px] mt-[-16px]">
                {errors.email}
              </p>
            )}
            <textarea
              name="message"
              className="bg-white rounded border border-gray-400 focus:outline-none h-32 focus:border-indigo-500 text-base px-4 py-2 mb-4 resize-none"
              placeholder="Сообщение"
              value={formState.message}
              onChange={handleChange}
            ></textarea>
            {errors.message && (
              <p className="text-red-300 text-sm break-words mb-1">
                {errors.message}
              </p>
            )}
            <button 
              className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
              type="submit"
              disabled={loading}
            >
              {loading ? 'Отправка...' : 'Отправить'}
            </button>
          </form>
        </div>
      </div>
      {formSubmitted && <ApplicationAcceptedModal />}
    </section>
  );
};

export default observer(MapContact);