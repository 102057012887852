import { useParams } from 'react-router-dom'
import ProjectStore from '../store/Project/Project.store'
import FetchStagesHandler from '../components/layout/FetchStagesHandler'
import { observer } from 'mobx-react-lite'
import { Spin } from 'antd'
import { useMount } from '../hooks/useMount'

const ProjectPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>()

  useMount(() => {
    ProjectStore.get({ projectId })
  })

  return (
    <div className="container px-5 mx-auto mt-8 mb-32">
      <FetchStagesHandler
        loader={
          <Spin
            size="large"
            className="w-full flex justify-center items-center h-[60vh]"
          />
        }
        state={ProjectStore.isProjectFetch}
      >
        <h2 className="text-6xl text-center font-bold my-16">{ProjectStore.data?.title}</h2>
        <img className="w-[60%] border-8 mx-auto my-8" src={ProjectStore.data?.image} alt=""/>
        <div className="container w-[70%] px-2 mx-auto">
          <p className="text-gray-700 text-center text-lg leading-loose px-4">{ProjectStore.data?.description}</p>
          {ProjectStore.data?.sections.map((section) => (
            <div key={section.id} className="px-4">
              <h3 className="text-xl text-center font-bold mb-6 mt-16">{section.title}</h3>
              <p className="text-gray-700 text-center text-lg leading-loose">{section.content}</p>
            </div>
          ))}
        </div>
      </FetchStagesHandler>
    </div>
  )
}

export default observer(ProjectPage)
