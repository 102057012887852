import { createLead } from '../services/create-lead.service';
import LeadData from '../interfaces/LeadData';

export const handleSubmit = async (
  leadData: LeadData,
  onSubmitted: () => void,
  setLoading: (loading: boolean) => void,
  setError: (error: string) => void
) => {
  setLoading(true);
  setError('');

  try {
    const result = await createLead(leadData);

    if (!result.success) {
      throw new Error(result.error);
    }
    onSubmitted();
  } catch (err: unknown) {
    if (err instanceof Error) {
      setError(err.message);
    } else {
      setError('Неизвестная ошибка');
    }
  } finally {
    setLoading(false);
  }
};
