import dvgLogo from "../../assets/dvgglogo.webp";
import { FC } from "react";
import CallNow from "../ui/Modals/CallNow";
import ApplicationAcceptedModal from "../ui/Modals/ApplicationAcceptedModal";
import useModalForm from "../../hooks/useModal";


const StartSection: FC = () => {
  const {
    isModalOpen,
    formSubmitted,
    handleOpenModal,
    handleCloseModal,
    handleFormSubmitSuccess,
  } = useModalForm("get_call");
  

  return (
    <section className="text-gray-600 body-font">
      <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <p className="title-font sm:text-4xl  text-3xl mb-4 font-medium text-gray-900">
            <h1 className="font-extrabold">
              IT импортозамещение?
            </h1>
            <p className="font-semibold">- DvG Project</p>
          </p>
          <h6 className="mb-8 leading-relaxed">
            <span className="mx-2"></span>Мы - ведущая
            компания по разработке программного обеспечения,
            перевоплощающая уникальные идеи в инновационные
            решения для вашего бизнеса. Воплощаем самые
            передовые технологии в полезные инструменты,
            отвечающие всем вашим потребностям. Наша команда
            опытных разработчиков гарантирует надежность и
            высокое качество наших продуктов. Мы нацелены на
            ваш успех и готовы помочь вам воплотить любые
            амбициозные планы. Доверьтесь нашему
            профессионализму и ощутите мощь инноваций!
          </h6>
          <div className="flex justify-center">
            <button onClick={handleOpenModal} className="inline-flex text-white bg-[#393185] border-0 py-2 px-6 focus:outline-none hover:bg-purple-800 rounded text-lg">
              Позвонить и заместить
            </button>
            <button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">
              Подробнее
            </button>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            data-aos-duration="1500"
            data-aos="fade-left"
            className="object-cover object-center rounded"
            alt="hero"
            src={dvgLogo}
          />
        </div>
      </div>
      {isModalOpen && <CallNow onClose={handleCloseModal} onSubmitSuccess={handleFormSubmitSuccess} />}
      {formSubmitted && <ApplicationAcceptedModal />}
    </section>
  );
};

export default StartSection;
